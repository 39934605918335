import React from "react";
import TitleHeader from "../../components/TitleHeader";
import Layout from "../../components/layout"


const MedycynaEstetyczna = () => {
  return (
    <Layout>
      <TitleHeader title="Medycyna estetyczna"/>

      <div className="grid-two-column content">
        <h2 className="first-column">Jeszcze piękniejsza</h2>
        <p className="second-column">
          Doskonałym uzupełnieniem do zabiegów stomatologii estetycznej są
          zabiegi z zakresu medycyny estetycznej oraz anty-aging. Dzięki
          holistycznemu podejściu do projektowania uśmiechu możemy sobie
          pozwolić nie tylko na pełnowymiarową estetykę twarzy ale również
          komfort, spójność i bezpieczeństwo w planowaniu procedur. Zabiegi
          medycyny estetycznej oprócz bezsprzecznie widocznych efektów
          wizualnych obejmują także: leczenie bruksizmu, wspomaganie efektów
          ortodontycznych i działają jak głęboko-wchłanialny zastrzyk pewności
          siebie.
        </p>
        <h2 className="first-column">Znieczulenia</h2>
        <p className="second-column">
          Do zabiegu oferujemy 3 rodzaje znieczulenia miejscowego do wyboru.
          toksyna botulinowa – od lat stosowana w medycynie, nie tylko relaksuje
          mięśnie odpowiedzialne za zmarszczki mimiczne ale także zapobiega ich
          powstawaniu. kwas hialuronowy – o strukturze usieciowanej w formie
          wypełniaczy służy do konturowania, uwydatniania lub uzupełniania
          ubytków tkankowych oraz spłycania zmarszczek i bruzd. Nieusieciowany
          służy do zabiegów rewitalizacji i regeneracji w procesie mezoterapii.
          mezoterapia – nazwa zabiegu pochodzi od połączenia słów “środek” i
          “leczenie”, polega na stosowaniu środków terapeutycznych bezpośrednio
          do wnętrza zmienionych tkanek, jest to zabieg dedykowany na cellulit,
          rozstępy, przebarwienia, łysienie. nici PDO – lifting i rewitalizacja
          a zarazem zagęszczanie skóry i spłycanie zmarszczek.
        </p>
      </div>
    </Layout>
  );
};
export default MedycynaEstetyczna;
